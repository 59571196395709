import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import routes from '../../../routes';

import communicationLanguageSpeech from '../../../images/educational-corner/blog17/Komunikacija-jezik-govor.png';
import lowFunctioningPSA from '../../../images/educational-corner/blog17/Nisko-funkcionirajuci-PSA.png';
import highFunctioningPSA from '../../../images/educational-corner/blog17/Visoko-funkcionirajuci-PSA.png';

const AutismSpectrumDisorder = () => {
  return (
    <PageNew
      title="KOKOLINGO - Poremećaj iz spektra autizma"
      description="Kada čitamo i govorimo o autizmu, možemo se susresti s raznim pojmovima, 
      kao što su pervazivni razvojni poremećaj, autistični poremećaj, Rettov poremećaj, 
      dezintegrativni poremećaj u djetinjstvu, Aspergerov poremećaj i nespecificirani 
      pervazivni razvojni poremećaj (PDD-NOS), što može biti zbunjujuće. 
      No danas, točnije od 2013. godine i petog izdanja Dijagnostičkog i 
      statističkog priručnika duševnih poremećaja (DSM – V) uvodi se jedinstveni 
      termin poremećaj iz spektra autizma (PSA)."
      headline="Poremećaj iz spektra autizma"
    >
      <Text as="p">
        Kada čitamo i govorimo o <i>autizmu</i>, možemo se susresti s raznim
        pojmovima, kao što su pervazivni razvojni poremećaj, autistični
        poremećaj, Rettov poremećaj, dezintegrativni poremećaj u djetinjstvu,
        Aspergerov poremećaj i nespecificirani pervazivni razvojni poremećaj
        (PDD-NOS), što može biti zbunjujuće. No danas, točnije od 2013. godine i
        petog izdanja Dijagnostičkog i statističkog priručnika duševnih
        poremećaja <br /> (DSM – V) uvodi se jedinstveni termin{' '}
        <b>
          <i>poremećaj iz spektra autizma (PSA).</i>
        </b>
      </Text>
      <Text as="p">
        Prema najnovijoj definiciji, on je neurorazvojni poremećaj koji
        karakteriziraju:
        <ol style={{ fontWeight: 'bold' }}>
          <li>teškoće u socijalnoj komunikaciji, odnosno interakciji te</li>
          <li>
            ograničeni i ponavljajući obrasci ponašanja, interesa i aktivnosti.
          </li>
        </ol>
      </Text>
      <Text as="h2">O uzrocima psa</Text>
      <Text as="p">
        Unatoč zaista brojnim istraživanjima, naprednim metodama genetskih
        analiza, snimanja mozga i ostalog, uzrok pojave PSA-a i dalje je dobrim
        dijelom nerazjašnjen.
      </Text>
      <Text as="p">
        <b>
          Glavnim se uzrocima u posljednje vrijeme smatraju utjecaji nasljeđa,
          moždana oštećenja i poremećaji moždane funkcije, biokemijske
          osobitosti, poremećaji kognitivnih procesa i jezično-govornog razvoja
          te poremećen emocionalni razvoj.
        </b>
      </Text>
      <Text as="p">
        Uvriježeno je mišljenje kako nema jedinstvenog uzroka, nego je riječ o
        interakciji nekolicine čimbenika.
      </Text>
      <Text as="h2">
        Koliko se često PSA javlja i zašto je taj broj toliko velik?
      </Text>
      <Text as="p">
        Centar za kontrolu i prevenciju bolesti u SAD-u (eng. CDC) iznosi
        podatke o <b>učestalosti od jednog na 68 djece dobi od 8 godina.</b>
      </Text>
      <Text as="p">
        Teško je odgovoriti zašto se PSA danas pojavljuje toliko često. Mnogo
        oprečnih teorija stoji iza toga pitanja i o tome se može zaista mnogo
        pročitati i čuti, a teško je donositi sudove o istinitosti navedenih
        podataka. Sa sigurnošću se može tvrditi jedino to da iza ovako velikog
        broja stoje sljedeće činjenice:
        <ol>
          <li>
            U samom nazivu dijagnoze stoji riječ <b>spektar</b>, što znači da
            osobe s PSA-om mogu funkcionirati zaista različito. Jednaku
            dijagnozu imaju odrasle osobe koje ne govore, ne mogu samostalno
            živjeti i brinuti se za sebe, ali i primjerice Anthony Hopkins,
            jedan od najpoznatijih i najtalentiranijih glumaca na svijetu.
          </li>
          <li>
            Današnji se stil života uvelike razlikuje od onog i ne tako davnog.
            Svi smo danas izrazito <b>informirani</b> jer je bilo koja
            informacija na svijetu od nas udaljena otprilike 5 klikova na našem
            mobitelu. Dakle, sve se više piše o PSA-u i važnosti rane
            intervencije pa se roditelji sve ranije javljaju svojim pedijatrima
            kada su zabrinuti za djetetov razvoj. Samim time, više će djece i s
            blažim znakovima PSA-a biti dijagnosticirano i evidentirano.
          </li>
        </ol>
      </Text>
      <Text as="h2">
        Poremećaji komunikacije, jezika i govora kod osoba s PSA-om
      </Text>
      <Text as="p">
        Pojmovi komunikacija, jezik i govor mogu zvučati kao gotovo iste stvari,
        ali se uvelike razlikuju. Više o njima možete pročitati{' '}
        <Link
          to={routes.EARLY_INTERVENTION_SPEECH_DEVELOPMENT}
          title="Rana intervencija i jezicno govorni razvoj"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>ovdje</b>
        </Link>
        .
      </Text>
      <Text as="p">
        Dakle ukratko, komunikacija je baza, temelj za razvoj jezika
        (razumijevanje govora) te na kraju i govora.
      </Text>
      <BlogImage
        imgSrc={communicationLanguageSpeech}
        imgAlt="Komunikacija, jezik, govor"
        imgTitle="Komunikacija, jezik, govor"
      />
      <Text as="p">
        To znači da, primjerice, ako Matej, dok se igra s tatom, ne gleda u istu
        igračku kojom se igraju i koju tata imenuje (
        <i>Vidi auto, ide brm-brm, vozi vozi, bum!</i>), ne može naučiti da je
        ta igračka auto i sve ono što auto radi.{' '}
        <b>
          To zovemo <i>združenom pažnjom</i> i ona omogućava usvajanje jezika.
        </b>{' '}
        To je ono što mala djeca s PSA-om ne usvoje na vrijeme i što nam
        najčešće prvo otkriva da je u pitanju ova vrsta teškoće.
      </Text>
      <Text as="p">
        Možemo na drugi način reći da djeca s PSA-om{' '}
        <b>ne razumiju što znači komunicirati</b>, dakle postoji teškoća u toj
        samoj bazi za usvajanje jezika i posljedično govora. To u praksi izgleda
        ovako - djeca s PSA-om često znaju imati relativno širok rječnik, što
        znači da znaju imenovati mnogo slika iz slikovnica, znaju brojiti do
        100, recitirati cijelu abecedu, cijele pjesmice napamet…{' '}
        <b>ali te riječi koje poznaju ne znaju funkcionalno upotrijebiti</b>.
        Kada budu žedna neće znati reći <i>Bocu!</i> ili <i>Tata, daj piti!</i>{' '}
        premda te riječi znaju izgovoriti.{' '}
        <b>
          Ne shvaćaju da je ta riječ sredstvo koje im omogućava da dobiju ono
          što žele
        </b>
        .
      </Text>
      <Text as="p">
        Ako dijete i ne govori, ono i dalje može znati komunicirati, a
        komunicirati se može i pogledom i pokazivanjem prstom. Kod djece s
        PSA-om <b>izostaje kontakt očima i gesta pokazivanja</b> jer su i to
        komunikacijska sredstva, a ta djeca ne razumiju kako komunikacija
        funkcionira (neki u većoj mjeri, a neki u manjoj).
      </Text>
      <Text as="p">
        Djeca s PSA-om koja s vremenom i shvate da je{' '}
        <b>govor sredstvo komunikacije</b> služit će se njime mnogo češće samo
        za to da ispune svoje želje i potrebe (
        <b>imperativna funkcija komunikacije</b> - <i>Daj mi! Hoću to!</i>), a
        ne da s bližnjima podijele nešto što im je zanimljivo ili ih veseli (
        <b>deklarativna funkcija komunikacije</b> -{' '}
        <i>Mama, mama, vidi toranj!</i>). Općenito, bit će manje komunikacijskih
        razmjena.
      </Text>
      <Text as="p">
        Jezično-govorne sposobnosti djece s PSA-om protežu se na kontinuumu od
        potpuno nerazvijenog funkcionalnog jezika i govora, preko atipičnog i
        stereotipnog govora (primjerice, ponavljanje naučenih rečenica s TV
        reklama ili obraćanje sebi u trećem licu) pa do urednog razvoja jezika i
        govora.
      </Text>
      <Text as="p">
        Novija istraživanja pokazuju{' '}
        <b>kako 20% populacije osoba s PSA-om ne razvije funkcionalni govor.</b>
      </Text>
      <Text as="h2">Zašto djeca s PSA-om lako pamte sadržaje s ekrana?</Text>
      <Text as="p">
        Zanimljivo je da djeca s PSA-om vrlo često brže i bolje usvoje engleski
        od hrvatskog jezika ili lako usvoje fraze koje upotrebljava, primjerice,{' '}
        <i>Peppa pig</i> nego ono što slušaju od roditelja ili prijatelja iz
        vrtića. Zašto je to tako?
      </Text>
      <Text as="p">
        Djeca urednog razvoja jezik uče spontano, iz socijalnih interakcija s
        ljudima koji ga okružuju, imitirajući ih. S druge strane, djeca s PSA-om
        imaju velike teškoće upravo s tim najvažnijim alatima za usvajanje
        jezika: imitacijom i socijalnom interakcijom. Socijalna im je
        interakcija otežana i na neki način zbunjujuća, ometajuća i time nije
        optimalan način učenja pa i ne mogu iz nje efikasno usvajati jezik.
        Također, vještine verbalnog i neverbalnog imitiranja su im smanjene.
      </Text>
      <Text as="p">
        Crtići s druge strane ne podrazumijevaju dvosmjernu komunikaciju, dijete
        može biti samo promatrač. Osim toga, <i>Peppa</i> se uvijek izražava
        istom intonacijom glasa i često upotrebljava iste fraze (
        <i>Svi vole skakati po blatnjavim lokvicama!</i>). To im uvelike
        olakšava usvajanje jezika, odnosno novih riječi i pojmova. Imitirati im
        je teško, no istraživanja su pokazala da će{' '}
        <b>
          osoba PSA-om s 80% većom točnošću imitirati radnju s videa nego što će
          to činiti uživo.
        </b>
      </Text>
      <Text as="h2">Na koje se sve načine očituje PSA?</Text>
      <Text as="p">
        Kao što je već spomenuto, PSA se očituje vrlo različito od djeteta do
        djeteta, odnosno osobe do osobe. Primjerice, navedeni kriterij teškoće u
        socijalnoj komunikaciji može biti izražen kao nerado stupanje u kontakt
        s vršnjacima, teško ostvarivanje interakcije iako želja za druženjem
        postoji ili kao jače izraženo osamljivanje.
      </Text>
      <Text as="p">
        Tko god je čitao nešto više o PSA-u mogao je vidjeti da osobe s PSA-om
        mogu biti <i>visokofunkcionirajuće</i> i <i>niskofunkcionirajuće</i>. Ta
        nam odrednica na prvu može dati okvirnu informaciju o tome kako se ta
        osoba može ponašati u određenim situacijama i primjerice kakav će
        školski sustav moći pohađati. No zašto su nam ta dva pojma preširoka i
        mogu nam dati površne informacije o toj osobi, pogledajmo na iduća dva
        primjera.
      </Text>
      <Text as="p">
        Zamislit ćemo dvoje djece s PSA-om od kojih se jedno opisuje kao
        visokofunkcionirajuće, a drugo niskofunkcionirajuće. No opisat ćemo i
        njihove vještine u području kontakta očima (pogleda u sugovornika),
        socijalne interakcije, govora, širine interesa, funkcioniranju unutar i
        izvan rutina te senzorne osjetljivosti. To su neke od važnijih
        odrednica, iako ih možemo navesti i više.
      </Text>
      <Text as="p">Primjer 1: Ivan</Text>
      <Text as="p">
        Recimo ovako, Ivan se opisuje kao dječak s visokofunkcionirajućim PSA-om
        jer ide u 4. razred redovite osnovne škole, ima odlične ocjene, govori i
        ima vrlo širok rječnik i mnogo različitih interesa. No Ivan teško stvara
        nova prijateljstva jer unatoč tome što se dobro izražava, osjeća
        nelagodu kada vršnjaka gleda u oči i ne zna kako bi ga pozvao na igru.
        Također, jučer nakon škole bio je vrlo nervozan jer na putu kući nije
        vidio <i>mali autobus broj 5</i> koji obično vozi u to vrijeme. Uz to,
        kada je došao kući, počeo je vikati i plakati jer je tata, promijenivši
        žarulju u lampi u dnevnom boravku, stavio jaču žarulju jer nije više
        imao one uobičajene <i>kakve Ivan voli</i>. Ta mu je nama neznatno jača
        svjetlost izrazito smetala i nije se mogao umiriti sve dok tata nije
        otišao do dućana i kupio onakvu žarulju <i>kakvu Ivan voli</i> i stavio
        je u lampu.
      </Text>
      <BlogImage
        imgSrc={highFunctioningPSA}
        imgAlt="Graf visokofunkcionirajućeg PSA"
        imgTitle="Graf visokofunkcionirajućeg PSA"
      />
      <Text as="p">Primjer 2: Maja</Text>
      <Text as="p">
        S druge strane, djevojčicu Maju opisuju kao niskofunkcionirajuću jer ne
        govori, nego komunicira s pomoću sličica simbola, ima vrlo oskudan
        kontakt očima i polazi također 4. razred, ali u specijaliziranoj
        ustanovi za odgoj i obrazovanje. Ali Maja je naučila ostvarivati dobru
        socijalnu interakciju unatoč tome što ne gleda sugovornike u oči i s
        pomoću svojih sličica uspijeva u potpunosti prenijeti sve svoje potrebe
        i misli, čak i neznancima, primjerice kada kupuje pecivo u pekari. Ima
        široke interese o kojima s drugima može komunicirati pomažući si
        sličicama, ne ovisi o rutinama i nema specifičnih senzornih
        osjetljivosti.
      </Text>
      <BlogImage
        imgSrc={lowFunctioningPSA}
        imgAlt="Graf niskofunkcionirajućeg PSA"
        imgTitle="Graf niskofunkcionirajućeg PSA"
      />
      <Text as="h2">
        Što dokazano pomaže u radu s djecom i osobama s PSA-om?
      </Text>
      <Text as="p">
        Nedostatan broj pružatelja usluga za djecu i osobe s PSA-om, kao i sve
        veća svjesnost o tom poremećaju otvorili su tržište za niz
        intervencijskih pristupa. Mnogi intervencijski pristupi koji se uvode u
        sustave socijalne skrbi i zdravstva{' '}
        <b>atraktivnog su nazivlja, no upitne kvalitete</b> i često ne
        podrazumijevaju interakciju s djetetom s PSA-om koja je kod ove skupine
        kvalitativno drukčija i teško ju je ostvariti.
      </Text>
      <Text as="p">
        <b>Znanstveno utemeljenim intervencijama</b> smatraju se one
        intervencije za koje postoje dostatni dokazi da rezultiraju pozitivnim
        učincima za djecu i osobe s PSA-om. Navest će se nekoliko pristupa koji
        dokazano pomažu u radu s djecom i osobama s PSA-om:
        <ol>
          <li>
            <i>Aktivno uključivanje roditelja u intervenciju</i>
            <Text as="p">
              Provodi se poučavanjem roditelja kako primijeniti različite
              strategije poticanja u domu i/ili u zajednici te kako na taj način
              unaprijediti širok raspon vještina i smanjiti nepoželjna
              ponašanja.
            </Text>
          </li>
          <li>
            <i>Rasporedi</i>
            <Text as="p">
              Poznato je kako djeca s PSA-om teško podnose promjene aktivnosti
              (vole rutine). Isto tako, pokazuju mnogo bolje sposobnosti
              vizualne obrade naspram auditivne. Djeci s PSA-om prelazak s
              aktivnosti na aktivnost olakšan je ako mogu predvidjeti što dolazi
              sljedeće te ako im je ono što se od njih očekuje prikazano
              vizualnim putem, npr. sličicama ili fotografijama.
            </Text>
          </li>
          <li>
            <i>Socijalne priče</i>
            <Text as="p">
              Socijalne su priče kratke, individualizirane priče izrađene da
              djetetu opisuju situacije, osobe, vještine, događaje ili koncepte
              u kojima se naglašavaju obilježja željenog oblika ponašanja, npr.
              što učiniti ako ti dijete u vrtiću uzme igračku iz ruke i jako se
              naljutiš?
            </Text>
          </li>
          <li>
            <i>Skriptiranje</i>
            <Text as="p">
              Skriptiranje je intervencija koja osobi s PSA-om pruža vodstvo
              kako se koristiti jezikom za započinjanje interakcije ili
              odgovaranje u određenim situacijama na način da joj se pruže fraze
              ili rečenice koje će joj pomoći uspješno sudjelovati u nekoj
              aktivnosti ili u interakciji (
              <i>Ja jako volim gledati filmove. Koji je tvoj omiljeni film?</i>
              ). U tome pomažu audio i videosnimke i/ili slike i tekstovi.
            </Text>
          </li>
          <li>
            <i>Poticanje jezične proizvodnje</i>
            <Text as="p">
              Pristupu koji se odnosi na poticanje jezične proizvodnje cilj je
              kod osobe s PSA-om uspostaviti sposobnost (vokalne) verbalne
              komunikacije, odnosno funkcionalne upotrebe govora. Ovdje se
              podrazumijeva i uvođenje <b>potpomognute komunikacije</b> (slike,
              geste).
            </Text>
          </li>
        </ol>
      </Text>
      <Text as="h2">Zaključno o terapijskim pristupima</Text>
      <Text as="p">
        <b>
          Tijekom rada s djetetom treba promatrati dijete kako bi se otkrilo što
          ga motivira te strukturirati učenje prema njegovim interesima
          (slijediti “vodstvo djeteta”)
        </b>
        . Dakle, ciljevi poticanja ostaju isti, ali se stručnjak, materijali i
        okolina prilagođavaju trenutačnom interesu i fokusu pažnje djeteta te se
        ciljano iskorištavaju situacije kako bi se dijete poučilo ciljanome
        ponašanju.
      </Text>
      <Text as="p">
        Primjerice, u terapiji je dijete vrlo suženih interesa koje još ne
        govori te ga se poučava komunikaciji s pomoću sličica (npr. <i>PECS</i>
        ). Obožava paliti i gasiti svjetlo. To je stereotipna radnja koje bismo
        se „htjeli riješiti“, no koristimo se njome kako bismo ga podučili
        komunikacijskim vještinama, odnosno da će dajući sličicu prekidača
        smjeti paliti i gasiti svjetlo. Time kao prvu riječ, tj. sličicu za
        podučavanje biramo sličicu <i>prekidača</i>. Iako bismo više voljeli da
        je ta riječ <i>mama, piškiti</i> ili <i>boli me</i>, krećemo od onoga
        što je djetetu najveća motivacija i uzbuđenje.
      </Text>
      <Text as="p">
        Kao što je vidljivo iz navedenoga primjera, slijediti „vodstvo djeteta”
        s PSA-om može predstavljati izazov kako roditeljima, tako i
        stručnjacima. Razlog je tome što vrlo često{' '}
        <b>
          dijete s PSA-om okolinu istražuje na drukčiji način od vršnjaka
          urednog razvoja i ne sudjeluje u svim aktivnostima i ponašanjima koje
          pretpostavlja njegova prirodna okolina
        </b>
        . Za to je stručnjacima, a napose roditeljima katkad potrebno mnogo
        strpljenja, kreativnosti, timskog rada i stručne podrške.
      </Text>
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Jančec, M., Šimleša, S. i Frey Škrinjar, J. (2016). Poticanje
            socijalne interakcije putem socijalnih priča u dječaka s poremećajem
            iz spektra autizma.{' '}
            <i>Hrvatska revija za rehabilitacijska istraživanja</i>, 52(1),
            87-99.
          </li>
          <li>
            Jezernik, N. (2016).{' '}
            <i>
              Jezične osobitosti predškolske djece s poremećajem iz spektra
              autizma.
            </i>{' '}
            Diplomski rad. Zagreb: Sveučilište u Zagrebu,
            Edukacijsko-rehabilitacijski fakultet.
          </li>
          <li>
            Popčević, K., Ivšac Pavliša, J., Bohaček, A.-M., Šimleša, S. i
            Bašić, B. (2016). Znanstveno utemeljene intervencije kod poremećaja
            iz spektra autizma.{' '}
            <i>Hrvatska revija za rehabilitacijska istraživanja</i>, 52(1),
            100-113.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default AutismSpectrumDisorder;
